(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:ContractCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('ContractCtrl', ContractCtrl);

  function ContractCtrl(user, company, companyByVat, _, FormControl, User, countries, $scope, Company, $q, backendUnreachable, orderType) {
    var vm = this
      , formControl = new FormControl()
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , selectedProductType = localStorage.getItem(EdenredECSselectedProductType)
      , isNewCardOrder = orderType === '1'
      , isMultiWalletSelected = selectedProductType === 'multiWallet'
      , nextStep = isNewCardOrder && isMultiWalletSelected ? 'multiWalletCardInvoice' : 'invoice';

    if (companyByVat.nameOnCard && (companyByVat.nameOnCard.length > 21)) {
      companyByVat.nameOnCard = null;
    }

    company = _.merge(company, companyByVat);
    vm.company = company;
    vm.selectedCompany = (!_.isEmpty(company)) ? company : null;

    setCompanyOwnerArray();

    vm.backendUnreachable = backendUnreachable;
    vm.countries = countries;
    vm.selectedOwner = {};
    vm.selectedOwnerIndex = 0;
    vm.user = user;
    vm.differentInvoiceAddress = false;
    vm.differentDeliveryAddress = false;
    vm.contractForm = {};

    vm.updateSelectedOwner = function updateSelectedOwner() {
      vm.selectedOwner = isLastOwner() ? {} : vm.company.owners[vm.selectedOwnerIndex];

      function isLastOwner() {
        return vm.company.owners[vm.selectedOwnerIndex] === _.last(vm.company.owners);
      }
    };

    vm.updateSelectedOwner();

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var validatableData = {
        email: user.email,
        password: user.password,
        name: vm.company.name,
        companyNumber: vm.company.companyNumber,
        vatNumber: vm.company.vatNumber
      };

      if (!vm.differentInvoiceAddress) {
        vm.company.invoiceAddress = _.defaultsDeep({}, vm.company.address);
      }

      if (!vm.differentDeliveryAddress) {
        vm.company.deliveryAddress = _.defaultsDeep({}, vm.company.address);
      }

      if (!vm.selectedOwner.isForeign) {
        vm.selectedOwner.hungarianAddress = _.defaultsDeep({}, vm.selectedOwner.address);
      }

      vm.company.owners = [_.defaultsDeep({}, vm.selectedOwner)];

      formControl
        .process(User.validateRegistration(validatableData).$promise)
        .then(function () {
          $scope.$emit('order.next', nextStep, 'contract');
        });
    };

    vm.getCompaniesByName = function getCompaniesByName(text) {
      return Company.query({name: text}).$promise;
    };

    vm.getCompaniesByTaxNum = function getCompaniesByTaxNum(text) {
      return Company.query({vatNumber: text}).$promise;
    };

    vm.updateCompany = function updateCompany(selectedCompany) {
      var deferredCompanyUpdate = $q.defer();

      if (!_.isUndefined(selectedCompany) && _.isEmpty(selectedCompany.companyNumber)) {
        Company
          .query({vatNumber: selectedCompany.vatNumber})
          .$promise
          .then(function (data) {
            deferredCompanyUpdate.resolve(_.defaultsDeep(new Company(), _.first(data)));
          });
      } else {
        deferredCompanyUpdate.resolve(selectedCompany);
      }

      deferredCompanyUpdate
        .promise
        .then(function (data) {
          if (_.isUndefined(data)) {
            return;
          }

          angular.forEach(Object.keys(vm.company), function (key) {
            vm.company[key] = data[key];
          });
          setCompanyOwnerArray();
          vm.selectedOwnerIndex = 0;
          vm.updateSelectedOwner();
        });
    };

    function setCompanyOwnerArray() {
      if (_.isEmpty(vm.company) || _.isUndefined(vm.company.owners)) {
        vm.company.owners = [{name: 'Egyéb'}];
      } else {
        vm.company.owners.push({name: 'Egyéb'});
      }
    }
  }
}());
